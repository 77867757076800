<template>
  <b-form @submit="submitField">
    <b-form-group :label="label">
      <b-input-group>
        <b-form-input :value="inputVal" :autofocus="!!autofocus"
                      @input="inputVal = $event; $emit('inputText', inputVal)"
                      @keydown.tab="submitField"
                      @keypress.enter="submitField"
                      @blur="submitField"/>
        <b-input-group-append>
          <t-button @click="submitField">+</t-button>
        </b-input-group-append>
      </b-input-group>
      <p-chip v-for="(val, indx) in values"
              :key='`badge-${indx}`'
              class="badge-full mr-1 ml-1 mt-2"
              removable
              @remove="removeVal(indx)">
        {{val}}
      </p-chip>
    </b-form-group>
  </b-form>
</template>

<script>

export default {
  name: 'bubbleInput',
  props: ['label', 'value', 'autofocus'],
  data () {
    return {
      inputVal: '',
      values: ['test1', 'test2']
    }
  },
  watch: {
    value: {
      handler () {
        this.values = this.value
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    submitField (e) {
      if (this.inputVal.length < 1) {
        return
      }
      if (!this.values) {
        this.values = this.$setCompat(this, 'values', [])
      }
      const newValues = this.inputVal.split(',').map(v => v.trim())
      this.values = [...this.values, ...newValues]
      this.inputVal = ''
      this.$emit('inputText', '')
      e.preventDefault()
      this.$emit('input', this.values)
      return false
    },
    removeVal (indx) {
      if (indx < this.values?.length) {
        this.values.splice(indx, 1)
        this.$emit('input', this.values)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.close-button {
 top: 1px;
}
</style>
